export default class FCustomerFilter {
  constructor(
      pageNo= 0,
      pageSize = 10,
      sortBy="id",
      order="DESC",
      search="",

      fdivisionIds =[],
      // fsubAreaIds=[],
      fcustomerGroupIds=[],
      statusActive= 0,

  ) {
    this.pageNo = pageNo;
    this.pageSize = pageSize;
    this.sortBy = sortBy;
    this.order = order;
    this.search = search;

    this.fdivisionIds = fdivisionIds;
    // this.fsubAreaIds = fsubAreaIds;
    this.fcustomerGroupIds = fcustomerGroupIds;
    this.statusActive = statusActive
  }

}
