<template>
  <v-card class="elevation-0">

    <v-card-title>
      {{ title }}
      <v-spacer></v-spacer>
        <v-text-field
            v-on:keyup.enter="searchOnEnter"
            v-on:blur="searchOnEnter"
            append-icon="mdi-magnify"
            hint="Kode, Deskripsi (press ENTER to search)"
            label="Search"
        ></v-text-field>
        <v-btn
            @click="showFilter = !showFilter"
            icon
            fab
            color="blue"
            small
            class="ml-2"
        >
          <v-icon>mdi-filter</v-icon>
        </v-btn>
    </v-card-title>
    <v-expand-transition>
      <v-card
          class="ml-2 mr-2 elevation-0 grey lighten-4"
          v-show="showFilter === true"
      >
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="4" md="4">
              <v-autocomplete
                  v-model="filterFdivisionBean"
                  :items="itemsFDivision"
                  item-value="id"
                  item-text="description"
                  dense
                  small-chips
                  hide-details
                  label="Divisi/Branch"
                  class="ml-1 mr-1"
                  multiple
                  clearable
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-autocomplete
                  v-model="filterFcustomerGroupBean"
                  :items="itemsFCustomerGroup"
                  item-value="id"
                  item-text="description"
                  dense
                  small-chips
                  hide-details
                  label="Jenis Customer"
                  class="ml-1 mr-1"
                  multiple
                  clearable
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-autocomplete
                  v-model="filterStatusActive"
                  :items="itemStatusActive"
                  item-value="id"
                  item-text="description"
                  dense
                  small-chips
                  hide-details
                  label="Status"
                  class="ml-1 mr-1"
                  clearable
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6" sm="2" md="2">
              <v-btn color="primary" small @click="fetchFCustomer">
                Terapkan
                <v-icon small class="ml-1" color="green lighten-2"
                >mdi-filter</v-icon
                >
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-expand-transition>
    <v-data-table
        v-model="selectedItems"
        :single-select="!multiSelect"
        :show-select="multiSelect"
        :headers="headers"
        :items="fCustomers"
        :page.sync="currentPage"
        :items-per-page="pageSize"
        hide-default-footer
        class="elevation-0"
        @page-count="totalTablePages =totalPaginationPages "
        align="start"
    >

      <template v-slot:top v-if="true">
        <v-row align="center" class="ml-4 mr-4">
          <v-switch
              v-model="multiSelect"
              :label="multiSelect?'Multi Select':'Single Select'"
              x-small
              class="pa-3"
          ></v-switch>
          <v-btn
              fab
              dark
              color="red accent-4"
              x-small
              :disabled="!multiSelect"
              class="mr-2"
              @click="deleteDialogMultiShow"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>

          <v-spacer></v-spacer>
          <v-menu offset-y style="align-items: start">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  class="mx-1"
                  fab
                  dark
                  x-small
                  color="blue"
                  v-bind="attrs"
                  v-on="on"
              >
                <v-icon>mdi-view-grid</v-icon>
              </v-btn>
            </template>
            <v-list color="grey lighten-4" class="mr">
              <v-list-item>
                <v-btn
                    plain
                    elevation="0"
                    color="gray darken-1"
                    small
                    @click="downloadMappingTemplate"
                >
                  <v-icon color="green" small>mdi-file-excel</v-icon>
                  Download Template
                </v-btn>
              </v-list-item>
              <v-list-item>
                <v-btn
                    plain
                    elevation="0"
                    color="gray darken-1"
                    small
                    @click="mappingDialogShow"
                >
                  <v-icon color="green">mdi-upload</v-icon>
                  Mapping from Template
                </v-btn>
              </v-list-item>

              <v-spacer></v-spacer>

              <v-list-item v-if="false">
                <v-btn
                    plain
                    elevation="0"
                    color="gray darken-1"
                    small
                >
                  <v-icon color="success" dark>mdi-file-excel</v-icon>
                  Export Excel
                </v-btn>
              </v-list-item>

            </v-list>
          </v-menu>
          <v-btn
              fab
              dark
              color="green"
              x-small
              @click="showDialogNew"
              :disabled="multiSelect"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-row>
      </template>


      <template v-slot:[`item.number`]="{ index }">
        {{ (index + ((currentPage-1)*pageSize) +1 )}}
      </template>


      <template v-slot:[`item.avatar`]="{ item}">
        <v-img
            :lazy-src="lookupImageUrlLazy(item)"
            :src="lookupImageUrl(item)"
            alt="avatar"
            width="80px"
            height="80px"
            class="ma-2 rounded"
        >
        </v-img>
        <div>
          {{ item.avatarImage }}
        </div>
      </template>

      <template v-slot:[`item.custname`]="{ item }">
        <div class="subtitle-2">
          {{item.custname}}
        </div>
        <div class="subtitle-2 font-weight-light" v-if="item.custno">
          *{{item.custno}}
        </div>
        <div v-if="item.npwp" class="caption small">
          NPWP <span class="font-italic">{{item.npwp}}</span>
        </div>
      </template>

      <template v-slot:[`item.address1`]="{ item }">
        <div class="fill-height">
          <div>
            {{ item.address1 }}
          </div>
          <div class="caption small" v-if="item.address2">
            {{ item.address2 }}
          </div>
          <div>
            {{ item.district1 }} - <span class="subtitle-2">{{ item.city1 }}</span>
          </div>
          <div v-if="item.phone || item.email" >
            <span v-if="item.phone"><v-icon x-small>mdi-phone-classic</v-icon> {{ item.phone }}</span>
            <span class="ml-1 caption" v-if="item.email"><v-icon x-small>mdi-email</v-icon>{{item.email}}</span>
          </div>
        </div>

      </template>

      <template v-slot:[`item.fdivisionBean`]="{ item }">
        <div class="fill-height">
          <div  class="caption">
            {{ lookupFDivision(item.fdivisionBean) }}
          </div>
          <div>
            <v-chip
                :color="getColorStatusActive(item.statusActive)"
                dark
                x-small
            >
              {{ item.statusActive===true?'Aktif': 'Non-Aktif' }}
            </v-chip>
          </div>
        </div>
      </template>

      <template v-slot:[`item.statusActive`]="{ item }">
        <v-chip
            :color="getColorStatusActive(item.statusActive)"
            dark
            x-small
        >
          {{ item.statusActive==true?'Aktif': 'Non-Aktif' }}
        </v-chip>
      </template>


      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
            @click="showDialogEdit(item)"
            icon
            :disabled="multiSelect"
        >
          <v-icon
              small
              color="warning"
          >
            mdi-pencil
          </v-icon>
        </v-btn>

        <v-btn
            v-if="false"
            @click="deleteDialogShow(item)"
            icon
            :disabled="multiSelect"
        >
          <v-icon
              small
              color="red accent-4"
          >
            mdi-delete
          </v-icon>
        </v-btn>
      </template>

    </v-data-table>

    <v-container>
      <v-row justify="end" align="center">
        <v-col
        cols="4"
        md="2"
        sm="2"
        >
          <v-select
              v-model="pageSize"
              :items="pageSizes"
              label="Items per page"
          ></v-select>
        </v-col>
        <v-col
            cols="10"
            md="9"
            sm="8"
            align="right"
        >
          <v-pagination
              v-model="currentPage"
              :length="totalPaginationPages"
              total-visible="8"
              circle
          ></v-pagination>
        </v-col>
      </v-row>
    </v-container>

    <DeleteDialog
        ref="refDeleteConfirmDialog"
        @eventFromDeleteConfirmDialog1="deleteItemConfirmedSingleSelect"
        @eventFromDeleteConfirmDialog2="deleteItemConfirmedMultiSelect"
    ></DeleteDialog>

    <FCustomerDialog
        :formMode.sync="formMode"
         :itemsFDivision="itemsFDivision"
         :itemsFCustomerGroup="itemsFCustomerGroup"
         :itemsFDistributionChannel="itemsFDistributionChannel"
          ref="refFormDialog"
          @eventFromFormDialogNew="saveDataNew"
          @eventFromFormDialogEdit="saveDataEdit"
    ></FCustomerDialog>

    <FCustomerMappingDialog
        ref="refImportDialog"
        :itemsFDivision="itemsFDivision"
        @eventFromImportDialog="importExcelCompleted">
    </FCustomerMappingDialog>

    <v-snackbar
        v-model="snackbar"
    >
      {{ snackBarMesage }}
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </v-card>
</template>

<script>
import FCustomerService from '../../../services/apiservices/f-customer-service';
import FDivisionService from "../../../services/apiservices/f-division-service";
import FileService from "../../../services/apiservices/file-service"
import FDistributionChannelService from '../../../services/apiservices/f-distribution-channel-service'
import FCustomerGroupService from '../../../services/apiservices/f-customer-group-service'

import DeleteDialog from "../../../components/utils/DeleteConfirmDialog";
import FCustomerDialog from "./FCustomerDialog";
import FormMode from "../../../models/form-mode";
import FCustomer from '../../../models/f-customer'

import {format, parseISO} from "date-fns";
import FCustomerMappingDialog from "@/components/mitra/customer/FCustomerMappingDialog.vue";
import * as XLSX from "xlsx";
import FCustomerFilter from "@/models/payload/f-customer-filter";
import FSubAreaService from "@/services/apiservices/f-sub-area-service";

export default {
  components: {FCustomerMappingDialog, FCustomerDialog, DeleteDialog},
  data () {
    return {
      showFilter: false,
      title: 'CUSTOMER',

      snackbar: false,
      snackBarMesage: '',

      multiSelect: false,
      selectedItems: [],
      imageBrokenLink: './assets/img_sample/sample-1.jpeg',

      currentPage: 1,
      totalTablePages: 1,
      totalPaginationPages: 1,
      pageSize: 10,
      pageSizes: [10, 25, 50, 150, 500],

      search: '',
      headers: [
        {
          text: 'No',
          value: 'number',
          width: '8%',
          sortable: false
        },
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'avatar',
          width: '20%'
        },
        { text: 'Member/Customer', value: 'custname', width:'30%' },
        { text: 'Address (Default)', value: 'address1', width: '35%' },
        { text: 'Branch/Distributor', value: 'fdivisionBean' },
        { text: 'Actions', value: 'actions', align:'start', sortable: false },
      ],

      formMode: '',
      itemSelectedIndex: -1,
      itemSelected: '',
      fCustomers:[],
      itemsFDivision: [],
      itemsFCustomerGroup: [],
      itemsFSubArea: [],
      itemsFDistributionChannel: [],

      itemStatusActive: [
        {id: 0, description: "Semua"},
        {id: 1, description: "Aktif"},
        {id: 2, description: "Tidak Aktif"},
      ],
      filterFdivisionBean: "",
      filterFsubAreaBean: "",
      filterFcustomerGroupBean: "",
      filterStatusActive: 1

    }
  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (value) this.fetchFCustomer()
      }
    },
    pageSize: {
      handler: function (value) {
        const refreshData = (this.currentPage===1)
        this.currentPage =1
        if (refreshData) {
          console.log("Change PageSize " + value)
          this.fetchFCustomer()
        }
        // console.log("page size = " + value + " >> " + this.totalPages)
      }
    },
  },
  computed: {
    currentUser(){
      return this.$store.state.auth.user;
    },
    // fCustomers(){
    //   return this.fCustomers
    // },
    itemsFArea(){
      return this.$store.state.data.listFArea
    },
    itemsFRegion(){
      return this.$store.state.data.listFRegion
    },
    itemsFSalesman(){
      return this.$store.state.data.listFSalesman
    }

  },
  methods: {
    downloadMappingTemplate(){
      const templateData = [
        { "Kode Cust": "", "Nama Customer": "", "InCode-1": "", "InCode-2": "", "InCode-3": "" },
      ];
      // 2. Membuat worksheet dari data template
      const worksheet = XLSX.utils.json_to_sheet(templateData);
      // 3. Menyesuaikan lebar kolom
      worksheet["!cols"] = [
        { wch: 10 }, // Lebar kolom untuk "Kode Cust"
        { wch: 25 }, // Lebar kolom untuk "Nama Customer" (lebih panjang)
        { wch: 15 }, // Lebar kolom untuk "InCode-1"
        { wch: 15 }, // Lebar kolom untuk "InCode-2"
        { wch: 15 }, // Lebar kolom untuk "InCode-3"
      ];

      // 4. Membuat workbook dan menambahkan worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Template");

      // 5. Menulis dan mengunduh file Excel
      XLSX.writeFile(workbook, "Template Mapping Customer.xlsx");

    },

    mappingDialogShow() {
      this.$refs.refImportDialog.showDialog();
      // console.log("hello bos")
    },
    importExcelCompleted(value) {
      if (value === "OKE") {
        this.fetchFCustomer()
      }
    },


    searchOnEnter(event){
      if (this.search !== event.target.value) {
        this.currentPage = 1;
        this.search = event.target.value;
        this.fetchFCustomer();
      }
    },
    fetchParent(){
      this.$store.dispatch('data/loadFSalesman')
      this.$store.dispatch('data/loadFSubArea')
      this.$store.dispatch('data/loadFArea')
      this.$store.dispatch('data/loadFRegion')

      if (this.currentUser.organizationLevel === "DIV") {
        FDivisionService.getFDivisionById(this.currentUser.fdivisionBean).then(
            response=>{
              this.itemsFDivision = [response.data]
              // console.log(response.data.items)
            },
            error=>{
              console.log(error.response)
            }
        )
      }else {
        FDivisionService.getAllFDivision().then(
            response => {
              // console.log(JSON.stringify(response.data))
              this.itemsFDivision = response.data
            },
            error => {
              console.log(error.response)
            }
        )
      }

      FCustomerGroupService.getAllFCustomerGroupByCompany().then(
          response =>{
            this.itemsFCustomerGroup = response.data
            // console.log(`Hello sini ${this.itemsFCustomerSalesBrand}`)
          },
          error=>{
            console.log(error.response)
          }
      )
      FSubAreaService.getAllFSubArea().then(
          response =>{
            this.itemsFSubArea = response.data
            // console.log(`Hello sini ${this.itemsFCustomerSalesBrand}`)
          },
          error=>{
            console.log(error.response)
          }
      )
      FDistributionChannelService.getAllFDistributionChannelByCompany().then(
          response =>{
            this.itemsFDistributionChannel = response.data
            // console.log(`Hello sini ${this.itemsFCustomerSalesBrand}`)
          },
          error=>{
            console.log(error.response)
          }
      )

    },
    fetchFCustomer() {
      const fcustomerFilter = new FCustomerFilter();
      fcustomerFilter.pageNo = this.currentPage;
      fcustomerFilter.pageSize = this.pageSize;
      fcustomerFilter.search = this.search;

      fcustomerFilter.fdivisionIds = this.filterFdivisionBean;
      // fcustomerFilter.fsubAreaIds = this.filterFsubAreaBean;
      fcustomerFilter.fcustomerGroupIds = this.filterFcustomerGroupBean;
      fcustomerFilter.statusActive = this.filterStatusActive;

      if (fcustomerFilter.fdivisionIds.length == 0) fcustomerFilter.fdivisionIds = [];
      // if (fcustomerFilter.fsubAreaIds.length == 0) fcustomerFilter.fsubAreaIds = [];
      if (fcustomerFilter.fcustomerGroupIds.length == 0) fcustomerFilter.fcustomerGroupIds = [];
      FCustomerService.getAllFCustomerContainingExt(fcustomerFilter).then(
          response => {
            // console.log(response.data)
            const { items, totalPages} = response.data
            this.fCustomers = items
            this.totalPaginationPages = totalPages
            
          },
          error => {
            console.log(error.response)
          }
      )

    },

    linkNeated(link){
      return link.replace(/\s+/g, '-').toLocaleLowerCase()
    },


    showDialogNew() {
      this.itemSelectedIndex =-1
      const itemModified = Object.assign({}, '')
      // this.formDialogShow = true
      this.formMode = FormMode.NEW_FORM
      // this.$refs.refFormDialog.showDialog(this.itemSelectedIndex, itemModified, this.itemsFDivision, this.itemsCustomerType,
      //     this.itemsFCustomerSalesBrand, this.itemsFCustomerGroup3, this.itemsFCustomer, this.itemsFWarehouse, this.itemsFPegawai)
      this.$refs.refFormDialog.showDialog(this.itemSelectedIndex, itemModified,
          this.itemsFSubArea, this.itemsFArea, this.itemsFRegion)
    },
    saveDataNew(itemFromRest){
      this.itemSelected = itemFromRest
      this.closeDialog()
      /**
       * Setiap data baru harus terlihat
       */
      // this.fBeritas.push(this.itemSelected)
      // this.fBeritas = [itemFromRest].concat(this.fBeritas)
      // this.fBeritas.unshift(itemFromRest)
      this.fetchFCustomer()
    },

    showDialogEdit (item) {
      this.itemSelectedIndex = this.fCustomers.indexOf(item)
      const itemModified = Object.assign({}, item)
      this.formMode = FormMode.EDIT_FORM
      // this.$refs.refFormDialog.showDialog(this.itemSelectedIndex, itemModified, this.itemsFDivision,  this.itemsCustomerType,
      //     this.itemsFCustomerSalesBrand, this.itemsFCustomerGroup3, this.itemsFCustomer, this.itemsFWarehouse, this.itemsFPegawai)
      this.$refs.refFormDialog.showDialog(this.itemSelectedIndex, itemModified,
          this.itemsFSubArea, this.itemsFArea, this.itemsFRegion)
    },
    saveDataEdit(item){
        this.itemSelected = item
        try {
          Object.assign(this.fCustomers[this.itemSelectedIndex], this.itemSelected)
        }catch (e){
          this.fetchFCustomer()
          e.toString()
        }
        this.closeDialog()

    },


    deleteDialogShow (item) {
      // console.log(item)
      this.itemSelectedIndex = this.fCustomers.indexOf(item)
      this.itemSelected = Object.assign({}, item)
      // this.myConfirmDelete = true
      this.$refs.refDeleteConfirmDialog.showDialog(this.itemSelectedIndex, item.username)
    },
    deleteDialogMultiShow(){
      if (this.multiSelect===true){
        // console.log(`nilai ${this.selectedItems}`)
        this.$refs.refDeleteConfirmDialog.showDialogMulti(this.selectedItems, `${this.selectedItems.length} items selected`)
      }
    },
    deleteItemConfirmedSingleSelect(index){
      console.log("delete SingleItem: " + index)

      const deletedItem = this.fCustomers[this.itemSelectedIndex]
      FCustomerService.deleteFCustomer(deletedItem.id).then(
        () => {
          // console.log("hapus bos " + response.data + " >> " + this.itemSelectedIndex)
          this.fCustomers.splice(this.itemSelectedIndex, 1)
          this.closeDialog()
        },
        error => {
          console.log(error)
          this.snackBarMesage = 'gagal hapus (digunakan oleh data anakk)'
          this.snackbar = true
          this.$refs.refDeleteConfirmDialog.setDialogState(false)
        }
      )
    },
    deleteItemConfirmedMultiSelect(items){
      // console.log(okeMessage)
      if (items.length >-1){
        let itemIds = []
        for (let i=0; i<items.length; i++){
            itemIds.push(items[i].id)
        }
        // console.log("Item idex: " + itemIds)

        FCustomerService.deleteAllFCustomer(itemIds).then(
            response => {
              if (response.data) {
                  for (let i = 0; i < items.length; i++) {
                    if (! response.data.includes(items[i].id)  || response.data.length === 0) {
                      const index = this.fCustomers.indexOf(items[i])
                      this.fCustomers.splice(index, 1)
                    }
                    if (response.data.length > 0) {
                      this.snackBarMesage = `${response.data.length} items gagal hapus (digunakan oleh data anak)`
                      this.snackbar = true
                    }

                    // console.log("Undeleted Items: " + response.data + " = " + items[i].id)

                }
                this.closeDialog()
              }
            },
            error => {
              console.log("error " + error)
            }
        )

        // console.log(items)
        // FCustomerService.deleteAllFCustomers()
      }

    },
    closeDialog () {
      // this.myConfirmDialog = false
      this.formMode = ''
      this.$refs.refDeleteConfirmDialog.setDialogState(false)
      this.$refs.refFormDialog.setDialogState(false)

      this.$nextTick(() => {
        this.itemSelected = Object.assign({}, new FCustomer())
        this.itemSelectedIndex = -1
      })
    },

    getColorStatusActive (trueFalse) {
      if (trueFalse === true) return 'green'
      else if (trueFalse === false) return 'gray'
      else return 'gray'
    },

    lookupFDivision (fdivisionBean) {
      const str = this.itemsFDivision.filter(x => x.id===fdivisionBean)
      if (str.length>0){
        return str[0].description
      }else {
        return '-'
      }
    },
    lookupFSalesman (fsalesmanBean) {
      const str = this.itemsFSalesman.filter(x => x.id===fsalesmanBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },

    lookupImageUrl(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return require('@/assets/images/no_image_available.jpeg')
      }else {
        return FileService.image_url_low(item.avatarImage)
      }
    },
    lookupImageUrlLazy(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return require('@/assets/images/no_image_available.jpeg')
      }else {
        return FileService.image_url_verylow(item.avatarImage)
      }
    },

    onImgError: function(event) {
      /**
       * masih belum fungsi
       */
      console.log(event)
    },
    computedDateFormattedDatefns (value) {
      return value ? format(parseISO(value), 'dd-MMM-yyyy') : ''
    },
    formattedCurrencyValue(prefix, value){
      if(! value){ return "0"}
      // return "Rp." + parseFloat(value).toFixed(0).toLocaleString()
      return prefix + "" + parseFloat(value).toLocaleString()
    },

  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login')
    }else {
      this.fetchParent()
      this.fetchFCustomer()

    }

  }

}
</script>

<style scoped>

</style>
